import logo from './logo.svg';
import './App.css';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import {useState, useEffect} from 'react';
import {FilterMatchMode} from 'primereact/api';
import {InputText} from 'primereact/inputtext';



function App() {

  const [resolution, setResolution] = useState('1h');
  const [model, setModel] = useState('stochastic_rsi');
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [filters, setFilters] = useState({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS}
  });

  const proxyUrl = "https://cryptic-peak-18087.herokuapp.com/"
  const endpoint = "https://0qyqu01xt1.execute-api.us-east-1.amazonaws.com/v1/models";
  

  function refreshData() {
    fetch(proxyUrl+endpoint+"?resolution="+resolution+"&model="+model)
        .then(res => {
          if (!res.ok) { // error coming back from server
            throw Error('could not fetch the data for that resource');
          } 
          return res.json();
        })
        .then(data => {
  
          setData(JSON.parse(data['model']));
          setError(null);
          console.log(data)
        })
        .catch(err => {
          // auto catches network / connection error
          setError(err.message);
        })
  }



  useEffect(() => {
    refreshData();
  }, []);


  useEffect(() => {
    refreshData();
  }, [resolution, model]);


  const smaScoreColor = (rowData) => {
    const stockClassName = classNames(' w-3rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
        'bg-green-100 text-green-900': rowData.Sma_Score === 3,
        'bg-red-100 text-red-900': rowData.Sma_Score === -3,
        'bg-yellow-100 text-yellow-900': rowData.Sma_Score === 1 || rowData.Sma_Score === -1
    });
    return <div className={stockClassName}>{rowData.Sma_Score}</div>;
};

const emaScoreColor = (rowData) => {
  const stockClassName = classNames('w-3rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-green-100 text-green-900': rowData.Ema_Score === 3,
      'bg-red-100 text-red-900': rowData.Ema_Score === -3,
      'bg-yellow-100 text-yellow-900': rowData.Ema_Score === 1 || rowData.Ema_Score === -1
  });
  return <div className={stockClassName}>{rowData.Ema_Score}</div>;
};

const strendScoreColor = (rowData) => {
  const stockClassName = classNames('w-4rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-green-100 text-green-900': rowData.Strend_Color === 'green',
      'bg-red-100 text-red-900': rowData.Strend_Color === 'red',
  });
  return <div className={stockClassName}>{rowData.Strend_Color}</div>;
};

const haScoreColor = (rowData) => {
  const stockClassName = classNames('w-4rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-green-100 text-green-900': rowData.Ha_Color === 'green',
      'bg-red-100 text-red-900': rowData.Ha_Color === 'red',
  });
  return <div className={stockClassName}>{rowData.Ha_Color}</div>;
};

const rsiLsColor = (rowData) => {
  const stockClassName = classNames('w-4rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-green-100 text-green-900': rowData.Rsi_Ls === 'long',
      'bg-red-100 text-red-900': rowData.Rsi_Ls === 'short',
  });
  return <div className={stockClassName}>{rowData.Rsi_Ls}</div>;
};

const rsiPhaseColor = (rowData) => {
  const stockClassName = classNames('w-6rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-green-100 text-green-900': rowData.Rsi_Phase === 'oversold',
      'bg-red-100 text-red-900': rowData.Rsi_Phase === 'overbought',
  });
  return <div className={stockClassName}>{rowData.Rsi_Phase}</div>;
};


const yearlyExtColor = (rowData) => {
  const stockClassName = classNames('w-6rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-green-100 text-green-900': rowData.Yearly_Ext > 0,
      'bg-red-100 text-red-900': rowData.Yearly_Ext < 0,
  });
  return <div className={stockClassName}>{rowData.Yearly_Ext}</div>;
};

const monthlyExtColor = (rowData) => {
  const stockClassName = classNames('w-6rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-green-100 text-green-900': rowData.Monthly_Ext > 0,
      'bg-red-100 text-red-900': rowData.Monthly_Ext < 0,
  });
  return <div className={stockClassName}>{rowData.Monthly_Ext}</div>;
};

const weeklyExtColor = (rowData) => {
  const stockClassName = classNames('w-6rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm', {
      'bg-green-100 text-green-900': rowData.Weekly_Ext > 0,
      'bg-red-100 text-red-900': rowData.Weekly_Ext < 0,
  });
  return <div className={stockClassName}>{rowData.Weekly_Ext}</div>;
};


  return (
    <div className="App">

      <header>
        <h1>Vaylor Trades</h1>
        <center>Indicators | Alerts | Backtests</center>
      </header>
      

      <br/><br/><br/>

      <label for="resolution">Resolution</label>
           <select name="resolution" required="required" type="text" placeholder="1h" onChange={(x) => setResolution(x.target.value)}>
           <option value="1h">1h</option>
           <option value="1d">1d</option>
           <option value="1w">1w</option>
          </select>
          <br/>
          <br/>

      <label for="model">Model</label>
           <select name="model" required="required" type="text" placeholder="stochastic_rsi" onChange={(x) => setModel(x.target.value)}>
           <option value="stochastic_rsi">Stochastic RSI</option>
           <option value="key_levels">Key Levels</option>
           <option value="sma">SMA</option>
           <option value="ema">EMA</option>
           <option value="supertrend">Supertrend</option>
           <option value="heikin_ashi">Heikin Ashi</option>
          </select>
          <br/>
          <br/>

      <InputText onInput={(e) =>
          setFilters({
            global: {value: e.target.value, matchMode: FilterMatchMode.CONTAINS}
          })
        }
        />
      
      {(model === 'sma') && (data) && (
        <DataTable value={data} sortMode="multiple" filters={filters} paginator rows={25}>
        <Column field='Symbol' header='Symbol' sortable/>
        <Column field='Name' header='Name' sortable/>
        <Column field='Category' header='Category' sortable/>
        <Column field='Sma_Score' header='Score' body={smaScoreColor} sortable/>
        <Column field='Sma_Score_Streak' header='Streak' sortable/>
        <Column field='Sma_50_200' header='50/200' sortable/>
        <Column field='Sma_50_Ext' header='50 Ext' sortable/>
        <Column field='Sma_200_Ext' header='200 Ext' sortable/>
        <Column field='Date' header='Date' sortable/>
        </DataTable>
      )}

{(model === 'ema') && (data) && (
        <DataTable value={data} sortMode="multiple" filters={filters} paginator rows={25}>
        <Column field='Symbol' header='Symbol' sortable/>
        <Column field='Name' header='Name' sortable/>
        <Column field='Category' header='Category' sortable/>
        <Column field='Ema_Score' header='Score' body={emaScoreColor} sortable/>
        <Column field='Ema_Score_Streak' header='Streak' sortable/>
        <Column field='Ema_50_200' header='50/200' sortable/>
        <Column field='Ema_50_Ext' header='50 Ext' sortable/>
        <Column field='Ema_200_Ext' header='200 Ext' sortable/>
        <Column field='Date' header='Date' sortable/>
        </DataTable>
      )}


{(model === 'supertrend') && (data) && (
        <DataTable value={data} sortMode="multiple" filters={filters} paginator rows={25}>
        <Column field='Symbol' header='Symbol' sortable/>
        <Column field='Name' header='Name' sortable/>
        <Column field='Category' header='Category' sortable/>
        <Column field='Strend_Color' header='Color' body={strendScoreColor} sortable/>
        <Column field='Strend_Streak' header='Streak' sortable/>
        <Column field='Strend_Price' header='Strend Price' sortable/>
        <Column field='Strend_Ext' header='Pct Ext' sortable/>
        <Column field='Date' header='Date' sortable/>
        </DataTable>
      )}


{(model === 'heikin_ashi') && (data) && (
        <DataTable value={data} sortMode="multiple" filters={filters} paginator rows={25}>
        <Column field='Symbol' header='Symbol' sortable/>
        <Column field='Name' header='Name' sortable/>
        <Column field='Category' header='Category' sortable/>
        <Column field='Ha_Color' header='Color' body={haScoreColor} sortable/>
        <Column field='Ha_Streak' header='Streak' sortable/>
        <Column field='Date' header='Date' sortable/>
        </DataTable>
      )}



{(model === 'stochastic_rsi') && (data) && (
        <DataTable value={data} sortMode="multiple" filters={filters} paginator rows={25}>
        <Column field='Symbol' header='Symbol' sortable/>
        <Column field='Name' header='Name' sortable/>
        <Column field='Category' header='Category' sortable/>
        <Column field='Rsi_K' header='Rsi K' sortable/>
        <Column field='Rsi_D' header='Rsi D' sortable/>
        <Column field='Rsi_Ls' header='Rsi Ls' body={rsiLsColor} sortable/>
        <Column field='Rsi_Phase' header='Rsi Phase' body={rsiPhaseColor} sortable/>
        <Column field='Date' header='Date' sortable/>
        </DataTable>
      )}


{(model === 'key_levels') && (data) && (
        <DataTable value={data} sortMode="multiple" filters={filters} paginator rows={25}>
        <Column field='Symbol' header='Symbol' sortable/>
        <Column field='Name' header='Name' sortable/>
        <Column field='Category' header='Category' sortable/>
        <Column field='Price' header='Price' sortable/>
        <Column field='Yearly_Open' header='Year Open' sortable/>
        <Column field='Monthly_Open' header='Month Open' sortable/>
        <Column field='Weekly_Open' header='Week Open' sortable/>
        <Column field='Yearly_Ext' header='Year Ext' body={yearlyExtColor} sortable/>
        <Column field='Monthly_Ext' header='Month Ext' body={monthlyExtColor} sortable/>
        <Column field='Weekly_Ext' header='Week Ext' body={weeklyExtColor} sortable/>
        <Column field='Date' header='Date' sortable/>
        </DataTable>
      )}



    </div>
  );
}

export default App;
